
export const Environment ={
    /*BENESHTY*/
  /* baseURL:'https://apitest.geniussystemapi.com',*/
  baseURL:'https://beneshtyapi.geniussystemapi.com', 
    baseURL2:'https://adminapi.beneshty.com' ,
  /*   Envio:'itemDashboar */
    /*kOKYsTORE*/
/*     baseURL:'https://kokystoreapi.geniussystemapi.com',
    baseURL2:'https://admin.kokyandgody.com' */
    /*PM*/ 
      /*   baseURL:'https://mehraelapi.geniussystemapi.com',
  
baseURL2:'https://adminapi.mehrailpm.com'  */
 }

export default Environment