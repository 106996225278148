import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import style from '../../Styles/login.module.css'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Redux/slices/UserSlice';
import axios from 'axios'
import Environment from '../../config';
import { db } from '../../firebase';
import { collection, addDoc, onSnapshot, query, orderBy } from 'firebase/firestore';

const Login = () => {
    const navigate = useNavigate()
    const [userCode, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const { loading, error } = useSelector((state) => state.user);
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const { userName, firebaseToken, token, usr } = useSelector(
        (state) => state.user
      );
      console.log(firebaseToken,usr,'hh')
    console.log(password)
    function login(e) {
        e.preventDefault();
        const reqData = {
           userCode,
           userPassword:encodeURIComponent(password),           
            }
        toast.loading('...loading')
        dispatch(loginUser(reqData)).then((result) => {
            console.log(result.payload,'ggg')
            localStorage.setItem('itemPicpremision', result.payload.data.userPermision?.dealWithItemsPic)
            localStorage.setItem('categoryPicpremision', result.payload.data.userPermision?.dealWithCategoryPic)
            localStorage.setItem('bannerPicpremision', result.payload.data.userPermision?.dealWithBannerPic)
            localStorage.setItem('notificationPremision', result.payload.data.userPermision?.dealWithNotification)
            localStorage.setItem('chatPremision', result.payload.data.userPermision?.dealWithChat)
            localStorage.setItem('firebaseToken', result.payload.data.firebaseToken)

            if (result.payload) {
                setCode('');
                setPassword('');
                navigate('/')

                localStorage.setItem('userCode', userCode)
                
            }
            else {
                toast.error(error)
            }
        })
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            login(e);
        }
    };
    useEffect(() => {
        if (userCode) {
        axios.get(`${Environment.baseURL}/api/admin/AdminAuth/getUsername?userCode=${userCode}`, { 
        })
            .then((response) => {
                console.log(response.data)
                setName(response.data.data)

            }).catch((err) => { console.log(err.response) })
        }
    }, [userCode])
    return (
        <>
            <Container>
                <section className={`${style.loginpage}`}>
                    <Row >
                        <Col>
                            <h3 className={`${style.signin}`}>Sign in</h3>
                            <div className={`${style.signin__form}`}>
                                <Form.Group className="mb-3 " controlId="email">
                                    <Form.Label className={`${style.label}`}>Enter your code </Form.Label>
                                    <Form.Control name="userCode" type='text' autoComplete="off" placeholder='' className={`${style.full}`} value={userCode} onChange={(e) => setCode(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-3 mt-4 " controlId="password">
                                    <Form.Label className={`${style.label}`}>Password</Form.Label>
                                    <Form.Control name="password"
                                        type='password'
                                        autoComplete="off"
                                        placeholder=''
                                        className={`${style.full}`}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        ref={formRef}
                                        onKeyPress={handleKeyPress} />
                                </Form.Group>
<p className={`${style.paraa}`}> Welcome {name}</p>
                                <button className={style.log__btn} type='button' onClick={login}>Sign In</button>             
                            </div>
                        </Col>
                    </Row>
                    <ToastContainer />
                </section>
            </Container>
        </>
    )
}

export default Login
