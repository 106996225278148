import React, { useState, useEffect } from 'react'
import styles from '../Styles/Nav.module.css'
import { Link, NavLink, useNavigate } from 'react-router-dom';

const Nav = (props) => {


    return (
        <>
            <div className={`${styles.navbar}`}>
                <div className={`${styles.wrapper}`}>
                    <div className={`${styles.logo__body}`}>
                        <Link to='/'>
                            <p className={`${styles.logo__title}`} > Genius System (Admin Panel)</p>
                        </Link>
                    </div>
                    <div className={`${styles.items}`}>
                        <div className={`${styles.item} ${styles.side}`}>
                        
                           
                          
                        </div>
                        
                    </div>
                </div>

            </div>
        </>
    )
}

export default Nav
